import React, { useState, useEffect, useRef } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Layout from '../components/Layout/Layout';
import Header from '../components/Header/Header-2';
import SEO from "../components/seo";
import ContactPopup from '../components/Popup/contact-popup';

const getdata = graphql`
{
  wpgraphql {
    page(id: "cG9zdDo3MjYz") {
      uri
      seo {
        canonical
        metaDesc
        metaKeywords
        focuskw
        title
        twitterTitle
        twitterDescription
        opengraphDescription
        opengraphPublishedTime
        opengraphModifiedTime
        opengraphTitle
        opengraphType
        opengraphImage {
          sourceUrl
        }
      }
    }
  }
}
`
const Pricing = () => {
  const data = useStaticQuery(getdata);
  const uri = '/pricing';
  const desc = data.wpgraphql.page.seo.metaDesc;
  const seo = data.wpgraphql.page.seo;
  seo.title = "Pricing";
  seo.opengraphPublishedTime = (new Date()).toISOString();
  seo.opengraphModifiedTime = (new Date()).toISOString();
  //
  const [planList, setPlanList] = useState([]);
  //
  const childRef = useRef();
  const openPopup = () => {
    childRef.current?.open();
  }
  //
  useEffect(() => {
    fetch(`https://dev.trustyip.com/plans?type=3`)
      .then((response) => response.json())
      .then((plans) => {
        setPlanList(plans?.data);
        //
        localStorage.setItem("planList", JSON.stringify(plans?.data));
      });
  }, []);
  return (
    <>
      <Layout>
        <SEO title="Pricing" description={desc} canonical={uri} seo={seo} />
        <Header home={false} menu='pricing' />
        {/* <div className="container py-3"> */}
        <main>
          <section className="pricing-banner-title py-5">
            <div className="container">
              <h2 className="page-title text-white">Pricing</h2>
            </div>
          </section>


          <div className="container py-3">
            <section>
              <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
                <h1 className="display-4 fw-normal text-body-emphasis">Pricing</h1>
                <p className="fs-5 text-muted">PowerPatent is the most advanced AI supported software for patent drafting.<br />
                  Sign up now to get the time saving and benefits of AI in patent drafting.
                </p>

              </div>
            </section>

            <div className="row row-cols-1 row-cols-md-3 mb-3 text-center">
              {
                planList.map((p, i) => {
                  return (
                    <div className="col">
                      <div className="card mb-4 rounded-3 shadow-sm">
                        <div className={"card-header py-3 " + (i === 1 ? "" : "")}>
                          {
                            p.id === 9 ?
                              <h6 style={{ fontSize: '0.9rem' }}>UtilityBuilder(R)</h6>
                              :
                              <h6 style={{ fontSize: '0.9rem' }}>PatentBuilder(R)</h6>
                          }
                          <h4 className="my-0 fw-normal">{p.title}</h4>
                        </div>
                        <div className="card-body" style={{ minHeight: '290px' }}>
                          <h1 className="card-title pricing-card-title">{p.content}</h1>
                          <ul className="mt-3 mb-4" style={{ textAlign: 'left' }}>
                            {
                              p.features.split('|').map(f => {
                                return (
                                  <li>{f}</li>
                                )
                              })
                            }
                          </ul>
                        </div>
                        <div className="card-footer bg-white">
                          <Link to={'/sign-up?planId=' + p.id}>
                            <button type="button" className={"w-100 btn btn-lg " + (p.id === 10 ? "btn-primary" : "btn-outline-primary")}>
                              {p.id === 10 ? "BEST VALUE" : "PURCHASE"}
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>)
                })
              }

              {
                planList &&
                <div className="col">
                  <div className="card mb-4 rounded-3 shadow-sm">
                    <div className="card-header py-3">
                      <h6 style={{ fontSize: '0.9rem' }}>PatentBuilder(R) PRO</h6>
                      <h4 className="my-0 fw-normal">
                        Enterprise License</h4>
                    </div>
                    <div className="card-body" style={{ minHeight: '290px' }}>
                      <ul className="list-unstyled mt-3 mb-4">
                        <li>All the benefits of Professional Plan</li>
                        <li>Enterprise license discounts</li>
                      </ul>
                    </div>
                    <div className="card-footer bg-white">
                      <button onClick={openPopup} type="button" className="w-100 btn btn-lg btn-outline-primary">Contact us</button>
                    </div>
                  </div>
                </div>
              }

            </div>
          </div>

          <div className="container">
            <div className="py-4">
              <div className="box-shadow anim-box bg-white">
                <div className="row">
                  <div className="col-md-12">
                    <div className="pricing-header p-3 pb-md-4 mx-auto">
                      <h1 className="display-4 fw-normal text-body-emphasis text-center">How PowerPatent Works!</h1>
                      <p className="fs-5 text-muted text-center">
                      PowerPatent is the most advanced, easy to use AI Assisted Patent Drafting Software
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row gap-md-0 gap-4 align-items-top flex-md-row-reverse flex-column">
                  <div className="col-md-12">
                    <div className="text-center">
                      <iframe width="914" height="514" src="https://www.youtube.com/embed/vFrDI8msJ8w" title="Powerpatent Demo 2025" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <ContactPopup ref={childRef}></ContactPopup>
      </Layout>
    </>
  )
}
export default Pricing
